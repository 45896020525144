.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
  text-align: center;
  display: flex;
  /* justify-content: center; */
  width: 100vw;
  min-height: 90vh;
  overflow: hidden;
  justify-content: space-between;
  flex-direction: column;
  /* background: linear-gradient(#fff, #999); */
}

html, body, #root {
  font-family: "Outfit Variable" !important;
  width: 100%;
  height: 100%;
}

#root > div:nth-child(1) {
  height: 100%;
  overflow: auto;
}

div#header:nth-of-type(1) {
  height: 64px;
}

#root > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.apptitle {
  font-family: "Raleway";
}
.appsubtitle {
  font-family: "Raleway";
}




* {
  user-select: none;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
}

.card-container {
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.card {
  position: relative;
  background-color: #fff;
  width: 300px;
  height: 400px;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.card-container,
.card {
  width: 300px;
  height: 400px;
  aspect-ratio: 3/4;
}

.card-content {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 2px;
  display: flex;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border: none;
  color: #fff;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons button:hover {
  transform: scale(1.05);
}

.buttons button:disabled {
  opacity: 0.5;
  transform: none;
}

.login-panel {
  display: flex;
  flex-direction: column;
}

.nav-footer-link {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

#gallery-container > div {
  height: 100%;
}

#building-detail-container > div {
  height: 100%;

}

progress {
  width: 100%;
  height: 0.2em;
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

progress::-webkit-progress-bar {
  background-color: #e2e8f0;
}

progress::-webkit-progress-value {
  background-color: #0284c7;
}

progress::-moz-progress-bar {
  background-color: #0284c7;
}

.gallery-tile {
  margin: 0px;
}

.gallery-tile > a{
  /* margin: 10px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.gallery-tile > a > img {
  height: 100%;
}

/* Vertical rectangle progress indicators */
.swipe-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 8px 0;
  width: 100%;
  z-index: 40;
  max-width: 80%;
  margin: 0 auto;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.swipe-progress-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.swipe-progress-indicator {
  height: 16px;
  width: 4px;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
}

.swipe-progress-indicator.completed {
  background-color: #0284c7;
}

.swipe-progress-indicator.pending {
  background-color: #e2e8f0;
}

.conversation-img-tile {
  margin: 0px;
}

.conversation-img-tile > a{
  /* margin: 10px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.conversation-img-tile > a > img {
  height: 100%;
}










/* Reset grayscale for all Leaflet overlay elements */
.leaflet-overlay-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-marker-icon,
.leaflet-zoom-animated,
.leaflet-interactive
{
/*  filter: grayscale(100%); */ 
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}


.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.settings-pane {
  height: 100%;

}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* 
@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
} */

@theme {
  --color-button-write: rgba(1, 1, 1, 1);
}